// TODO: MOVE TO ./CONTEXTS FOLDER
import React, { useReducer, createContext, useContext, useEffect } from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';
import type { FontValueComponentFactoryReturnType } from '~/components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { EnablePreviewInteractionsType } from '~/components/manage/EditWebsite/EditWebsitePreviewPanel/previewInteractionUtils';
import type { FullComponentFontView } from './util/mappers/types';
import { mapButtonStylesToComponent, ButtonProps } from './util/mappers/mapButtonStyles';
import { mapLinkStylesToComponent, LinkProps } from './util/mappers/mapLinkStyles';
import {
  DEFAULT_BODY_FONT_VALUES,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_HEADER_FONT_VALUES,
} from './constants';

export type WebsiteThemeContextStateType = {
  components: {
    // Global
    backgroundColors: {
      nav: string;
      homepageContent: string;
      content: string;
      global: string;
    };
    globalHeaderFontValues: FullComponentFontView;
    globalBodyFontValues: FullComponentFontView;
    styleGlobalBodyFont: FontValueComponentFactoryReturnType;
    globalAccentColor: string;
    globalInputFieldStyles: SerializedStyles;
    // Cms
    cmsEntityComponentHeaderFontValues: FullComponentFontView;
    styleCmsEntityHeaderFont: FontValueComponentFactoryReturnType;
    cmsEntityComponentBodyFontValues: FullComponentFontView;
    styleCmsEntityBodyFont: FontValueComponentFactoryReturnType;
    cmsEntityComponentBodyFont2Values: FullComponentFontView;
    styleCmsEntityBodyFont2: FontValueComponentFactoryReturnType;
    // Themed components
    ThemedButton: React.FC<ButtonProps>;
    CmsEntityBodyLink: React.FC<LinkProps>;
  };
  highlightedComponents: string[];
  wedding?: WPublicWeddingView;
  inPreview?: 'DESKTOP' | 'MOBILE';
  isMobileAppPreview?: boolean;
  pageDataContext?: {
    isHidden?: boolean;
    richPreviewImage?: string;
  };
  enableInPreviewInteractions?: EnablePreviewInteractionsType;
};

const initialState: WebsiteThemeContextStateType = {
  components: {
    ThemedButton: mapButtonStylesToComponent(
      DEFAULT_BODY_FONT_VALUES.fontFamily,
      DEFAULT_ACCENT_COLOR
    ),
    CmsEntityBodyLink: mapLinkStylesToComponent(DEFAULT_BODY_FONT_VALUES, DEFAULT_BACKGROUND_COLOR),
    globalBodyFontValues: DEFAULT_BODY_FONT_VALUES,
    styleGlobalBodyFont: () => () => <div />,
    globalHeaderFontValues: DEFAULT_HEADER_FONT_VALUES,
    globalAccentColor: DEFAULT_ACCENT_COLOR,
    globalInputFieldStyles: { name: '', styles: '' },
    cmsEntityComponentHeaderFontValues: DEFAULT_HEADER_FONT_VALUES,
    cmsEntityComponentBodyFontValues: DEFAULT_BODY_FONT_VALUES,
    cmsEntityComponentBodyFont2Values: DEFAULT_BODY_FONT_VALUES,
    styleCmsEntityHeaderFont: () => () => <div />,
    styleCmsEntityBodyFont: () => () => <div />,
    styleCmsEntityBodyFont2: () => () => <div />,
    backgroundColors: {
      nav: DEFAULT_BACKGROUND_COLOR,
      homepageContent: DEFAULT_BACKGROUND_COLOR,
      content: DEFAULT_BACKGROUND_COLOR,
      global: DEFAULT_BACKGROUND_COLOR,
    },
  },
  highlightedComponents: [],
  wedding: {
    wedding_account_id: 0,
    wedding_account_uuid: '',
    slug: '',
    website_effects: [],
  },
  isMobileAppPreview: false,
  pageDataContext: {},
  enableInPreviewInteractions: undefined,
};

// ACTIONS
const RECEIVE_WEDDING_VIEW = 'RECEIVE_WEDDING_VIEW';
const UPDATE_STATE = 'UPDATE_STATE';

type ReceiveWeddingAction = {
  type: typeof RECEIVE_WEDDING_VIEW;
  payload: WPublicWeddingView;
};
type UpdateStateAction = {
  type: typeof UPDATE_STATE;
  payload: Partial<WebsiteThemeContextStateType>;
};

type Action = ReceiveWeddingAction | UpdateStateAction;

export type WebsiteThemeContextType = {
  state: WebsiteThemeContextStateType;
  dispatch: (action: Action) => void;
};

const WebsiteThemeContext = createContext<WebsiteThemeContextType | undefined>({
  state: initialState,
  dispatch: () => null,
});

function websiteThemeReducer(state: WebsiteThemeContextStateType, action: Action) {
  switch (action.type) {
    case RECEIVE_WEDDING_VIEW: {
      return { ...state, wedding: action.payload };
    }
    case UPDATE_STATE: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

export const WebsiteThemeContextProvider: React.FC<{
  initialState: WebsiteThemeContextStateType;
}> = ({ initialState: initialStateProp, children }) => {
  const [state, dispatch] = useReducer(websiteThemeReducer, initialStateProp || initialState);
  const value = {
    state,
    dispatch,
  };

  // TODO: RE-FACTOR ACTIONS LIFTING HERE
  useEffect(() => {
    dispatch({ type: UPDATE_STATE, payload: initialStateProp });
  }, [initialStateProp]);

  return <WebsiteThemeContext.Provider value={value}>{children}</WebsiteThemeContext.Provider>;
};

export const useWebsiteThemeContext = () => {
  const context = useContext(WebsiteThemeContext);
  if (context === undefined) {
    throw new Error('useWebsiteTheme must be used within a WebsiteThemeContextProvider');
  }

  const { state, dispatch } = context;

  const receiveWeddingView = (payload: WPublicWeddingView) => {
    return dispatch({ type: RECEIVE_WEDDING_VIEW, payload });
  };

  const updateState = (payload: Partial<WebsiteThemeContextStateType>) => {
    return dispatch({ type: UPDATE_STATE, payload });
  };

  return { state, actions: { receiveWeddingView, updateState } };
};
