import styled from '@emotion/styled';
import { COLORS, FONT, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import * as COLOR_SYSTEM from '~/components/publicWebsiteV2/util/colorSystem';

const BUBBLE_SIZES = {
  SM: 20,
  MD: 24,
  LG: 28,
};
const BUBBLE_OFFSET_TOP = {
  SM: -10,
  MD: -15,
  LG: -20,
};
const BUBBLE_OFFSET_RIGHT = {
  SM: -10,
  MD: -20,
  LG: -25,
};

export const CountBubble = styled.div<{ size: 'SM' | 'MD' | 'LG'; isDarkMode?: boolean }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-family: ${FONT.FONT_FAMILY_SANS_SERIF};
  font-size: ${FONT.FONT_SIZE_EXTRA_SMALL};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  justify-content: center;
  position: absolute;

  ${({ size, isDarkMode }) => `
    background-color: ${
      isDarkMode ? COLOR_SYSTEM.DARK_ICON_COUNT_BG : COLOR_SYSTEM.LIGHT_CART_COUNT_BG
    };
    color: ${isDarkMode ? COLOR_SYSTEM.DARK_ICON_COUNT_TEXT : COLOR_SYSTEM.LIGHT_CART_COUNT_TEXT};
    height: ${BUBBLE_SIZES[size]}px;
    right: ${BUBBLE_OFFSET_RIGHT[size]}%;
    top: ${BUBBLE_OFFSET_TOP[size]}%;
    width: ${BUBBLE_SIZES[size]}px;
  `}

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    background-color: ${COLORS.RED_MESSAGING};
    color: ${COLORS.CLOUD};
  }
`;

export const IconContainer = styled.div<{ isDarkMode: boolean; addBoxShadow?: boolean }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;

  ${({ isDarkMode }) => `
    background-color: ${isDarkMode ? COLOR_SYSTEM.DARK_ICON_BG : COLOR_SYSTEM.LIGHT_ICON_BG};
    svg {
      fill: ${isDarkMode ? COLOR_SYSTEM.DARK_ICON_TEXT : COLOR_SYSTEM.LIGHT_ICON_TEXT};
    }
  `}

  ${({ addBoxShadow }) =>
    addBoxShadow &&
    `
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  position: relative;
`;
