import React, { useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';
import { hexToRgb } from '@zola/zola-ui/src//util/color/hexToRgb';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors';

export const computeButtonColors = (accentColor?: string) => {
  const defaultAccentColor = COLORS.NAVY.substring(1);
  const isAccentColorDark = isDarkColor(accentColor || defaultAccentColor);
  const fontColor = isAccentColorDark ? '#FFFFFF' : '#000000';
  const accentColorRGB = hexToRgb(accentColor);
  const { r, g, b } = accentColorRGB || { r: 0, g: 0, b: 0 };
  const shadeFactor = 0.4;
  const darkenedColor = `rgb(${r * (1 - shadeFactor)}, ${g * (1 - shadeFactor)}, ${
    b * (1 - shadeFactor)
  })`;
  const lightenedColor = `rgb(${r}, ${g}, ${b}, ${shadeFactor})`;
  return {
    backgroundColor: `rgb(${r}, ${g}, ${b})`,
    fontColor,
    backgroundColorHover: isAccentColorDark ? darkenedColor : lightenedColor,
  };
};

export const mapValuesToButtonStyles = (
  globalBodyFontFamily: string,
  accentColor?: string
): SerializedStyles => {
  const { fontColor, backgroundColor, backgroundColorHover } = computeButtonColors(accentColor);

  // NOTE: CHANGES TO THESE STYLES NEED TO BE CHANGED IN MAPVALUESTOBUTTONCOMPONENT AS WELL
  return css`
    background-color: ${backgroundColor};
    font-family: ${globalBodyFontFamily};
    color: ${fontColor};
    border-radius: 4px;
    border: none;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    width: 200px;

    &:hover {
      background-color: ${backgroundColorHover};
      color: ${fontColor};
      text-decoration: none;
    }
    &:disabled {
      opacity: 0.4;
    }
    text-align: center;
    &:visited {
      color: ${fontColor};
    }
    &:focus {
      text-decoration: none;
    }
  `;
};

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    React.AriaAttributes {}

// Currently only handles hex color
export const mapButtonStylesToComponent = (globalBodyFontFamily: string, accentColor?: string) => {
  const { fontColor, backgroundColor, backgroundColorHover } = computeButtonColors(accentColor);

  const ButtonComponent: React.FC<ButtonProps> = ({ children, style, ...rest }) => {
    const [hover, setHover] = useState(false);

    const inlineStyles = {
      backgroundColor: hover ? backgroundColorHover : backgroundColor,
      fontFamily: globalBodyFontFamily,
      color: fontColor,
    };

    const buttonEmotionStyles = css`
      border-radius: 4px;
      border: none;
      padding: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin: 0;
      width: 200px;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
      &:disabled {
        opacity: 0.4;
      }
      text-align: center;
      &:visited {
        color: inherit;
      }
      &:focus {
        text-decoration: none;
      }
    `;

    return (
      <button
        {...rest}
        style={{ ...inlineStyles, ...style }}
        css={buttonEmotionStyles}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </button>
    );
  };

  return ButtonComponent;
};
