import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import COLORS, { COLORS_TEXT, COLOR_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors';
import { Input } from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import {
  Input as DropdownInput,
  StyledCaret,
} from '@zola/zola-ui/src/components/Form/dropdownV3/DropdownV3.styles';
import {
  CharCountMessage,
  HelperText,
  FormLabel,
} from '@zola/zola-ui/src/components/Form/form.styles';
import FinalFormDropdown from '@zola/zola-ui/src/components/Form/dropdownV3/FinalFormDropdown';
import DropdownCheckboxList from '@zola/zola-ui/src/components/Form/DropdownCheckboxList';
import { ChipContainer } from '@zola/zola-ui/src/components/Chip/Chip.styles';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import ToggleV2 from '@zola/zola-ui/src/components/ToggleV2';
import { DARK_ERROR } from './colorSystem';

/* 
  Because InputFieldV3 has an issue where the `className` prop is passed deep into the <input /> field only
  and the main prop is called containerClassname, you can't use styled(InputFieldV3).

  This applies styles directly to the necessary StyledComponents imported from the @zola-ui component. Add this
  function as a css prop to the parent component that holds all Input fields that need to inherit these styles, e.g.
  <form css={mapModeThemeToInputField(isDarkMode)}>
    <InputFieldV3 {...props} />
  </form>
*/
export const mapModeThemeToInputField = (
  isDarkMode: boolean,
  bodyFontColor: string
): SerializedStyles => {
  return css`
    ${Input}:focus {
      border-color: ${isDarkMode ? COLORS.SCREAMING_GREY : COLORS.EMPEROR};
    }
    ${
      isDarkMode &&
      `
      [data-state='error'] {
        ${Input} {
          border-color: ${DARK_ERROR};
        }
        ${CharCountMessage} {
          color: ${DARK_ERROR}; 
        }
      }
    `
    }
    ${HelperText}, ${CharCountMessage} {
      color: #${bodyFontColor};
    }
    ${FormLabel} {
      color: #${bodyFontColor};
    }
  `;
};

export const mapModeThemeToCheckboxStyles = (
  isDarkMode: boolean,
  bodyFontColor?: string
): SerializedStyles => {
  const getLabelColor = () => {
    if (bodyFontColor) {
      return `#${bodyFontColor}`;
    }
    if (isDarkMode) {
      return COLORS.WHITE;
    }
    return COLORS.EMPEROR;
  };
  return css`
    .zola-ui.v2-render-selector-field {
      .selector-icon-wrapper:hover {
        border-color: ${isDarkMode ? COLORS.WHITE : COLORS.EMPEROR};
      }
      .selector-icon-wrapper--checked {
        background-color: ${isDarkMode ? COLORS.WHITE : COLORS.EMPEROR};
        border-color: ${isDarkMode ? COLORS.WHITE : COLORS.EMPEROR};

        svg {
          fill: ${isDarkMode ? COLORS.EMPEROR : COLORS.WHITE};
        }
      }
      ${isDarkMode &&
      `
          .checkmark-svg {
            color: ${COLORS.EMPEROR};
          }
        `}
      .selector-label {
        color: ${getLabelColor()};
        &--checked {
          color: ${getLabelColor()};
        }
      }
    }
  `;
};

export const GenericFinalFormDropdown = styled(FinalFormDropdown)<{
  isDarkMode: boolean;
}>`
  ${FormLabel} {
    color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.PRIMARY)};
  }
  ${StyledCaret} {
    color: ${COLORS_TEXT.BODY};
  }

  ${DropdownInput} {
    &:focus {
      border-color: ${COLORS_TEXT.PRIMARY};
    }
  }
`;

export const GenericDropdownCheckboxList = styled(DropdownCheckboxList)<{
  isDarkMode: boolean;
}>`
  svg {
    fill: ${COLORS_TEXT.BODY};
  }
  ${ChipContainer} {
    border-radius: 4px;
  }

  ${({ isDarkMode }) => mapModeThemeToCheckboxStyles(isDarkMode)}
`;

export const GenericToggle = styled(ToggleV2, {
  shouldForwardProp: (prop) => prop !== 'isDarkMode',
})<{ isDarkMode: boolean }>`
  label {
    font-weight: 600;
    color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.BODY)};
    text-transform: none;
    letter-spacing: 0;
  }
  .react-toggle {
    &:hover {
      .react-toggle-track {
        background-color: transparent;
      }
    }
    .react-toggle-track {
      background-color: transparent;
      border: 2px solid ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.SECONDARY)};
      padding: 2px;

      &:hover {
        background-color: transparent;
        border-color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.SECONDARY)};
      }

      span i {
        color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.SECONDARY)};

        &:hover {
          color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.SECONDARY)};
        }
      }
      [class*=' zola-ui-icon-']::before {
        font-weight: bold;
      }
    }
    &:active .react-toggle-thumb {
      box-shadow: none;
    }
    .react-toggle-thumb {
      border: 1px solid transparent;
      background-color: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS_TEXT.SECONDARY)};

      ${MEDIA_QUERY.EXCLUDE_MOBILE} {
        top: 4px;
        left: 4px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .react-toggle.react-toggle--checked .react-toggle-track {
    background-color: transparent;
    border-color: ${COLOR_SEMANTIC.POSITIVE};
    &:hover {
      background-color: transparent;
      border-color: darken(${COLOR_SEMANTIC.POSITIVE}, 5%);
    }

    span i {
      color: ${COLOR_SEMANTIC.POSITIVE};
    }
  }
  .react-toggle.react-toggle--checked .react-toggle-thumb {
    border-color: ${COLOR_SEMANTIC.POSITIVE};
    background-color: ${COLOR_SEMANTIC.POSITIVE};
    ${MEDIA_QUERY.EXCLUDE_MOBILE} {
      left: 25px;
    }
  }
  .react-toggle.react-toggle--checked {
    &:hover {
      .react-toggle-track {
        background-color: transparent;
      }
    }
  }
  .react-toggle.react-toggle--checked.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }
`;
