import type { WThemeGroupFontFamilyView, WPublicWeddingView } from '@zola/svc-web-api-ts-client';
import type { WebsiteThemeContextStateType } from '~/components/publicWebsiteV2/context';
import {
  DEFAULT_HEADER_FONT_VALUES,
  DEFAULT_BODY_FONT_VALUES,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from '~/components/publicWebsiteV2/constants';
import { fillFontProperties } from '~/components/publicWebsiteV2/util/mappers';
import { mapLinkStylesToComponent } from '~/components/publicWebsiteV2/util/mappers/mapLinkStyles';
import type { MediaQueryType } from '~/components/publicWebsiteV2/util/mappers/types';
import { mapButtonStylesToComponent } from '~/components/publicWebsiteV2/util/mappers/mapButtonStyles';
import { mapFontValuesToComponentFactory } from '~/components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';

import { mapModeThemeToInputField } from '~/components/publicWebsiteV2/util/genericFormElements.styles';

import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';

export const mapWeddingToComponents = ({
  wedding,
  mediaQuery,
}: {
  wedding?: WPublicWeddingView;
  mediaQuery: MediaQueryType;
}): WebsiteThemeContextStateType['components'] => {
  // font families for theme
  const globalHeaderFontFamily = wedding?.public_theme_v2?.theme_group_font_families
    ?.HEADER as WThemeGroupFontFamilyView;
  const globalBodyFontFamily = wedding?.public_theme_v2?.theme_group_font_families
    ?.BODY as WThemeGroupFontFamilyView;

  const {
    GLOBAL: globalComponent,
    CONTENT_CONTAINER_HOME: contentContainerHomeComponent,
    CONTENT_CONTAINER: contentContainerComponent,
    NAV: navComponent,
    CMS_ENTITY: cmsEntityComponent,
    FIXED_WIDTH_CONTAINER: fixedWidthContainerComponent,
  } = wedding?.public_theme_v2?.components || {};

  // properties from global component
  const { fonts, background, accent_color: accentColor } = globalComponent || {};
  const globalHeaderFont = fonts?.HEADER?.[1];
  const globalBodyFont = fonts?.BODY?.[1];
  const backgroundColor = background?.background_color;

  // create data structure to hold global fallback values
  // supplements values if global not provided
  const globalHeaderFontValues = fillFontProperties(DEFAULT_HEADER_FONT_VALUES, {
    ...globalHeaderFontFamily,
    ...globalHeaderFont,
  });
  const globalBodyFontValues = fillFontProperties(DEFAULT_BODY_FONT_VALUES, {
    ...globalBodyFontFamily,
    ...globalBodyFont,
  });
  const styleGlobalBodyFont = mapFontValuesToComponentFactory({
    fallback: globalBodyFontValues,
    mediaQuery,
  });

  // Fill in fallbacks for background color to store in context
  const globalBgColor = backgroundColor?.value || DEFAULT_BACKGROUND_COLOR;
  const navBgColor = navComponent?.background?.background_color?.value;
  const homepageBgColor = contentContainerHomeComponent?.background?.background_color?.value;
  const contentContainerBgColor = contentContainerComponent?.background?.background_color?.value;
  const fixedWidthBgColor = fixedWidthContainerComponent?.background?.background_color?.value;
  const backgroundColors = {
    nav: navBgColor || globalBgColor,
    homepageContent: fixedWidthBgColor || homepageBgColor || globalBgColor,
    content: fixedWidthBgColor || contentContainerBgColor || globalBgColor,
    global: globalBgColor,
  };

  const cmsEntityComponentHeaderFontValues = fillFontProperties(
    globalHeaderFontValues,
    cmsEntityComponent?.fonts?.HEADER?.[1]
  );
  const styleCmsEntityHeaderFont = mapFontValuesToComponentFactory({
    fallback: cmsEntityComponentHeaderFontValues,
    responsiveFontSize: true,
    mediaQuery,
    excludeFontSize: false,
  });

  const cmsEntityComponentBodyFontValues = fillFontProperties(
    globalBodyFontValues,
    cmsEntityComponent?.fonts?.BODY?.[1]
  );
  const styleCmsEntityBodyFont = mapFontValuesToComponentFactory({
    fallback: cmsEntityComponentBodyFontValues,
    mediaQuery,
  });

  const cmsEntityComponentBodyFont2Values = fillFontProperties(
    cmsEntityComponentBodyFontValues,
    cmsEntityComponent?.fonts?.BODY?.[2]
  );
  const styleCmsEntityBodyFont2 = mapFontValuesToComponentFactory({
    fallback: cmsEntityComponentBodyFont2Values,
    mediaQuery,
  });

  const isFixedWidthContainerDarkMode = isDarkColor(backgroundColors.content);
  // Input field to be used within fixed width container (not homepage)
  const globalInputFieldStyles = mapModeThemeToInputField(
    isFixedWidthContainerDarkMode,
    cmsEntityComponentBodyFontValues.color
  );
  const ThemedButton = mapButtonStylesToComponent(
    globalBodyFontFamily?.font_family?.name,
    accentColor
  );
  const CmsEntityBodyLink = mapLinkStylesToComponent(
    cmsEntityComponentBodyFontValues,
    backgroundColors.content
  );

  return {
    globalHeaderFontValues,
    styleGlobalBodyFont,
    globalBodyFontValues,
    globalAccentColor: accentColor || DEFAULT_ACCENT_COLOR,
    globalInputFieldStyles,
    backgroundColors,
    ThemedButton,
    CmsEntityBodyLink,
    cmsEntityComponentHeaderFontValues,
    styleCmsEntityHeaderFont,
    cmsEntityComponentBodyFontValues,
    styleCmsEntityBodyFont,
    cmsEntityComponentBodyFont2Values,
    styleCmsEntityBodyFont2,
  };
};

export default mapWeddingToComponents;
