import type {
  WAbsoluteThemeComponentAssetStyleView,
  WInFlowThemeComponentAssetStyleView,
  WLengthView,
} from '@zola/svc-web-api-ts-client';
import type { Device } from '~/lib/universal/utils/devices';

export type DevicePartial = Pick<
  Device,
  'isHighPixelDensity' | 'isDesktop' | 'isTablet' | 'isMobile'
>;

export const getTabletSize = (desktopSize: number): number => {
  return Math.floor((desktopSize * 2) / 3);
};

export const getMobileSize = (desktopSize: number): number => {
  return Math.floor(desktopSize / 3);
};

export const pickDeviceProperties = (device?: Device): DevicePartial | undefined =>
  device
    ? {
        isDesktop: device.isDesktop,
        isTablet: device.isTablet,
        isMobile: device.isMobile,
        isHighPixelDensity: device.isHighPixelDensity,
      }
    : undefined;

export const getRelativeImgSize = (
  style: WAbsoluteThemeComponentAssetStyleView | WInFlowThemeComponentAssetStyleView,
  width: number,
  device?: DevicePartial
): number => {
  let calculatedWidth = 0;

  const assetDesktopSize = style?.responsive_size?.desktop?.width?.value;
  const assetDesktopUnit = style?.responsive_size?.desktop?.width?.unit;

  let val = style?.responsive_size?.desktop?.width?.value;
  let unit = style?.responsive_size?.desktop?.width?.unit;

  if (device?.isTablet()) {
    const assetTabletSize = style?.responsive_size?.tablet?.width?.value;
    const assetTabletUnit = style?.responsive_size?.tablet?.width?.unit;
    if (assetTabletSize) {
      val = assetTabletSize;
      unit = assetTabletUnit;
    } else if (
      assetDesktopSize &&
      assetDesktopUnit === (('PIXEL' as unknown) as WLengthView.UnitEnum)
    ) {
      val = getTabletSize(assetDesktopSize);
    }
  } else if (device?.isMobile()) {
    const assetMobileSize = style?.responsive_size?.mobile?.width?.value;
    const assetMobileUnit = style?.responsive_size?.mobile?.width?.unit;

    if (assetMobileSize) {
      val = assetMobileSize;
      unit = assetMobileUnit;
    } else if (
      assetDesktopSize &&
      assetDesktopUnit === (('PIXEL' as unknown) as WLengthView.UnitEnum)
    ) {
      val = getMobileSize(assetDesktopSize);
    }
  }

  if (val && unit === (('PERCENTAGE' as unknown) as WLengthView.UnitEnum)) {
    calculatedWidth = Math.round(width * (val / 100));
  } else if (val) {
    calculatedWidth = val;
  }

  return device?.isHighPixelDensity() ? calculatedWidth * 2 : calculatedWidth;
};

const roundNumberUpToInterval = (num: number, interval: number) => {
  const roundedNumber = Math.ceil(num / interval) * interval;
  // Upper limit of 3000
  if (roundedNumber > 3000) return 3000;
  return roundedNumber;
};

export const getImageRequestWidth = (
  style: WAbsoluteThemeComponentAssetStyleView | WInFlowThemeComponentAssetStyleView,
  width: number,
  device?: DevicePartial
) => {
  const calculatedWidth = getRelativeImgSize(style, width, device);
  return roundNumberUpToInterval(calculatedWidth, 500);
};

export const appendWidthParam = ({
  src,
  style,
  width,
  device,
}: {
  src: string;
  style?: WAbsoluteThemeComponentAssetStyleView | WInFlowThemeComponentAssetStyleView;
  width?: number;
  device?: Pick<Device, 'isHighPixelDensity' | 'isDesktop' | 'isTablet' | 'isMobile'>;
}) => {
  if (!style || !width || !device) return src;

  const requestSize = getImageRequestWidth(style, width, device);
  if (requestSize === 0) return src;

  return `${src}?w=${requestSize}`;
};

// Used by MultiPageHeroMarquee
export const appendHeightParam = ({
  src,
  height,
  device,
}: {
  src: string;
  height: number;
  device?: DevicePartial;
}) => {
  const calculatedHeight = device?.isHighPixelDensity() ? height * 2 : height;
  const requestedHeight = roundNumberUpToInterval(calculatedHeight, 500);
  return `${src}${height > 0 ? `?h=${requestedHeight}` : ''}`;
};
