import fetch from 'isomorphic-fetch';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import ApiService from '../util/api';
import * as ActionType from './types/CartActionTypes';

function cacheBust() {
  return new Date().toString();
}

function requestSize() {
  return {
    type: ActionType.REQUEST_SIZE,
  };
}

function receiveSize(response) {
  return {
    type: ActionType.RECEIVE_SIZE,
    payload: response.data && response.data.items.length, // temporary
  };
}

function requestCart() {
  return {
    type: ActionType.REQUEST_CART,
  };
}

export function receiveCart(response) {
  return {
    type: ActionType.RECEIVE_CART,
    payload: response.data,
  };
}

export function resetCart() {
  return {
    type: ActionType.RESET_CART,
  };
}

function displayErrorMessage(dispatch, headline) {
  return dispatch(toastsActions.negative({ headline }));
}

// Temporary
export function getCartSize() {
  return (dispatch) => {
    dispatch(requestSize());
    return fetch(`/api/v0/cart?cache=${cacheBust()}`, {
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveSize(json)));
  };
}

export function getCart() {
  return (dispatch) => {
    dispatch(requestCart());
    return fetch(`/api/v0/cart?cache=${cacheBust()}`, {
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((json) => dispatch(receiveCart(json)))
      .catch((e) => {
        throw new Error(e);
      });
  };
}

export function addItem(addItemRequest) {
  return (dispatch) => {
    return fetch('/api/v0/cart/item', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(addItemRequest),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((json) => {
        if (json && json.errorMessage) {
          const error = new Error(json.errorMessage);
          error.response = json;
          displayErrorMessage(dispatch, json.errorMessage);
          window.dispatchEvent(
            new CustomEvent(`UPDATE_MAX_QUANTITY_${addItemRequest.collectionItemId}`, {
              detail: error,
            })
          );
          throw error;
        } else {
          return json;
        }
      });
  };
}

export function addItemV2(body) {
  return (dispatch) => {
    return ApiService.post('/web-api/v1/cart/add-item', body)
      .then(
        (json) =>
          new Promise((resolve) => {
            if (json && json.status === 'error') {
              displayErrorMessage(dispatch, json.message);
            }
            resolve(json);
          })
      )
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        displayErrorMessage(dispatch, error.message);
        throw error || response;
      });
  };
}
