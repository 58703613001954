import React from 'react';
import { css } from '@emotion/react';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { Z_INDEX_MAP, Z_INDEX_NAV_KEY } from '~/components/publicWebsiteV2/util/zIndexConstants';
import { mapFontValuesToSerializedStyles } from '~/components/publicWebsiteV2/util/mappers';
import NavigationMenu from '../NavigationMenu';

const DesktopHeader = (): JSX.Element => {
  const {
    state: {
      wedding,
      components: { globalBodyFontValues, backgroundColors },
    },
  } = useWebsiteThemeContext();
  const { public_theme_v2 } = wedding || {};
  const navBodyFontStyles = public_theme_v2?.components?.NAV?.fonts?.BODY?.[1];
  const navBackgroundColor = backgroundColors.nav;
  const headerEleStyles = css`
    background-color: #${navBackgroundColor};
    margin-left: auto;
    margin-right: auto;
    z-index: ${Z_INDEX_MAP[Z_INDEX_NAV_KEY]};
  `;

  const TitleStyles = css`
    a {
      ${mapFontValuesToSerializedStyles({
        fallback: globalBodyFontValues,
        partial: navBodyFontStyles,
        responsiveFontSize: true,
        mediaQuery: MEDIA_QUERY,
      })}
    }
  `;

  return <NavigationMenu css={headerEleStyles} titleStyles={TitleStyles} />;
};

export default DesktopHeader;
