import React from 'react';
import Head from 'next/head';

import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter';
import UnifiedNav from '~/components/common/header/UnifiedNav';
import BabyErrorPage from '@zola/zola-ui/src/components/BabyErrorPage/BabyErrorPage';
import styles from './index.module.less';

type CustomErrorProps = {
  statusCode: number;
};

const CustomErrorPage: React.FC<CustomErrorProps> = ({ statusCode = 404 }) => {
  return (
    <>
      <Head>
        <title>{statusCode === 404 ? 'Page Not Found' : 'Error Occurred'} - Zola Baby</title>
      </Head>
      <UnifiedNav />
      <BabyErrorPage className={styles.babyErrorPage} />
      <BabyFooter />
    </>
  );
};

export default CustomErrorPage;
