import styled from '@emotion/styled';
import type { WThemeGroupComponentBorderView } from '@zola/svc-web-api-ts-client';
import type { MediaQueryType } from '~/components/publicWebsiteV2/util/mappers/types';
import type { ZolaTheme } from '../../../../ZolaThemeProvider';
import { Z_INDEX_MAP, Z_INDEX_CONTENT_LAYER } from '../../util/zIndexConstants';

type UniversalContainerProps = {
  evenSpacing?: boolean;
  isSinglePage?: boolean;
  isMobilePreview?: boolean;
  isDesktopPreview?: boolean;
  hasBackgroundColor?: boolean;
};

const MAX_WIDTH = 1020;
export const MARGIN_DESKTOP_X = '10%';
export const MARGIN_MOBILE_X = '5%';

const getMarginStyles = ({
  borderWidth,
  addY,
  mediaQuery,
  evenSpacing,
  isDesktopPreview,
}: {
  // Border width; additional value to add to container width for max width
  borderWidth: number;
  // Whether to add margin to y-axis
  addY?: boolean;
  // Even spacing on top and bottom margin; addY must be true for changes to be applied
  evenSpacing?: boolean;
  mediaQuery: MediaQueryType;
  isDesktopPreview?: boolean;
}) => {
  const MARGIN_DESKTOP_TOP = '52px';
  const MARGIN_DESKTOP_BOTTOM = '100px';
  const MOBILE_MARGIN_TOP = '40px';
  const MOBILE_MARGIN_BOTTOM = '80px';

  const mobileMarginTop = evenSpacing ? MARGIN_MOBILE_X : MOBILE_MARGIN_TOP;
  const mobileMarginBottom = evenSpacing ? MARGIN_MOBILE_X : MOBILE_MARGIN_BOTTOM;

  const getDesktopMarginX = () => {
    if (isDesktopPreview) {
      return 'auto';
    }
    return MARGIN_DESKTOP_X;
  };

  return `
  ${
    addY
      ? `margin-top: ${MARGIN_DESKTOP_TOP};
      margin-bottom: ${evenSpacing ? MARGIN_DESKTOP_TOP : MARGIN_DESKTOP_BOTTOM};`
      : ''
  }
    @media (max-width: ${MAX_WIDTH + borderWidth * 2}px) {
        margin-left: ${getDesktopMarginX()};
        margin-right: ${getDesktopMarginX()};
    }
    ${mediaQuery.MOBILE} {
        ${
          addY
            ? `margin-top: ${mobileMarginTop};
            margin-bottom: ${mobileMarginBottom};`
            : ''
        }
        margin-left: ${MARGIN_MOBILE_X};
        margin-right: ${MARGIN_MOBILE_X};
    }
  `;
};

const getCommonContainerStyles = (
  extraSpacing?: number,
  isSinglePage?: boolean,
  mediaQuery?: MediaQueryType,
  isMobilePreview?: boolean
) => `
  ${isMobilePreview ? '' : `max-width: ${MAX_WIDTH + 2 * (extraSpacing || 0)}px;`}
  margin-left: ${isSinglePage ? MARGIN_DESKTOP_X : 'auto'};
  margin-right: ${isSinglePage ? MARGIN_DESKTOP_X : 'auto'};
  ${
    isSinglePage
      ? `${mediaQuery?.MOBILE} {
    margin-left: ${MARGIN_MOBILE_X};
    margin-right: ${MARGIN_MOBILE_X};
  }`
      : ''
  }
  position: relative;
  z-index: ${Z_INDEX_MAP[Z_INDEX_CONTENT_LAYER]};
`;

const mobilePaddingX = '5%';
const getSharedPadding = (theme: ZolaTheme, evenSpacing?: boolean) => {
  return `
  padding-top: 65px;
  padding-right: ${theme.SPACING.S32};
  padding-left: ${theme.SPACING.S32};
  ${evenSpacing ? 'padding-bottom: 65px;' : ''}
  ${theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding-right: ${mobilePaddingX};
    padding-left: ${mobilePaddingX};
    padding-top: ${theme.SPACING.XS};
    ${evenSpacing ? `padding-bottom: ${theme.SPACING.XS};` : ''}
  }
`;
};

export const getBasicBorderStyles = (
  border?: WThemeGroupComponentBorderView,
  borderColor?: string
) => {
  if (!border) return {};

  const mapBorderStyleEnumToStyle = (
    borderStyle?: WThemeGroupComponentBorderView.BorderStyleEnum
  ) => {
    switch (borderStyle) {
      case ('DOTTED' as unknown) as WThemeGroupComponentBorderView.BorderStyleEnum:
        return 'dotted';
      case ('DOUBLE' as unknown) as WThemeGroupComponentBorderView.BorderStyleEnum:
        return 'double';
      case ('SOLID' as unknown) as WThemeGroupComponentBorderView.BorderStyleEnum:
        return 'solid';
      default:
        return 'none';
    }
  };
  return {
    borderColor: `#${borderColor}`,
    borderWidth: `${border?.border_width || 0}px`,
    borderStyle: mapBorderStyleEnumToStyle(border?.border_style),
    borderRadius:
      border?.shape === (('ROUNDED' as unknown) as WThemeGroupComponentBorderView.ShapeEnum)
        ? '64px'
        : '0px',
  };
};

export const StandardContainer = styled.div<UniversalContainerProps & { borderWidth: number }>`
  ${({ isSinglePage, theme, isMobilePreview }) =>
    getCommonContainerStyles(0, isSinglePage, theme.MEDIA_QUERY, isMobilePreview)}
  ${({ theme, evenSpacing }) => getSharedPadding(theme, evenSpacing)}
  ${({ borderWidth, hasBackgroundColor, evenSpacing, theme, isDesktopPreview }) => {
    if (borderWidth > 0) {
      return getMarginStyles({
        borderWidth,
        addY: true,
        mediaQuery: theme.MEDIA_QUERY,
        evenSpacing,
        isDesktopPreview,
      });
    }
    if (hasBackgroundColor) {
      return getMarginStyles({
        borderWidth: 0,
        mediaQuery: theme.MEDIA_QUERY,
        evenSpacing,
        isDesktopPreview,
      });
    }
    return '';
  }}
`;

const SCOOPED_CORNER_CIRCLE_RADIUS = 60;
const SCOOPED_CORNER_CIRCLE_RADIUS_MOBILE = 30;

const getScoopedCornerBackgroundImageShape = (
  borderColor: string,
  containerBgColor?: string,
  borderWidth?: number,
  circleRadius = SCOOPED_CORNER_CIRCLE_RADIUS
) => {
  const getRadialGradient = (x: string | number, y: string | number) => `radial-gradient(
    circle at ${x} ${y},
    ${containerBgColor} ${circleRadius}px,
    #${borderColor} ${circleRadius}px,
    #${borderColor} ${circleRadius + (borderWidth || 0)}px,
    transparent ${circleRadius}px
  )`;
  return `background-image:
  ${getRadialGradient(0, 0)},
  ${getRadialGradient('100%', 0)},
  ${getRadialGradient(0, '100%')},
  ${getRadialGradient('100%', '100%')}`;
};

export const ScoopedContainer = styled.div<
  UniversalContainerProps & {
    circleBgColor?: string;
    borderColor: string;
    borderWidth?: number;
  }
>`
  ${({ isSinglePage, isMobilePreview }) =>
    getCommonContainerStyles(0, isSinglePage, undefined, isMobilePreview)}
  ${({ theme, evenSpacing, isDesktopPreview }) =>
    getMarginStyles({
      borderWidth: 0,
      addY: true,
      mediaQuery: theme.MEDIA_QUERY,
      evenSpacing,
      isDesktopPreview,
    })}
  padding: ${SCOOPED_CORNER_CIRCLE_RADIUS}px;
  ${(props) => props.theme.MEDIA_QUERY.MOBILE} {
    padding: ${SCOOPED_CORNER_CIRCLE_RADIUS_MOBILE}px;
  }

  ${({ circleBgColor, borderWidth, borderColor, theme }) =>
    `${getScoopedCornerBackgroundImageShape(borderColor, circleBgColor, borderWidth)};
    ${theme.MEDIA_QUERY.MOBILE} {
      ${getScoopedCornerBackgroundImageShape(
        borderColor,
        circleBgColor,
        borderWidth,
        SCOOPED_CORNER_CIRCLE_RADIUS_MOBILE
      )};
    }
    `}

  ${({ borderWidth, borderColor, theme }) =>
    borderWidth &&
    borderColor &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: calc(100% - ${SCOOPED_CORNER_CIRCLE_RADIUS * 2}px);
      transform: translate(-50%, -50%);
      border-left: ${borderWidth}px solid #${borderColor};
      border-right: ${borderWidth}px solid #${borderColor};
      z-index: -1;

      ${theme.MEDIA_QUERY.MOBILE} {
        height: calc(100% - ${SCOOPED_CORNER_CIRCLE_RADIUS_MOBILE * 2}px);
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% - ${SCOOPED_CORNER_CIRCLE_RADIUS * 2}px);
      height: 100%;
      transform: translate(-50%, -50%);
      border-top: ${borderWidth}px solid #${borderColor};
      border-bottom: ${borderWidth}px solid #${borderColor};
      z-index: -1;

      ${theme.MEDIA_QUERY.MOBILE} {
        width: calc(100% - ${SCOOPED_CORNER_CIRCLE_RADIUS_MOBILE * 2}px);
      }
    }
  `}
`;

/**
 * Given a border shape, calculates extra margin to add to a side
 */
const DOUBLE_BORDER_SPACE_BETWEEN = 8;
const DOUBLE_BORDER_SPACE_BETWEEN_WIDE = 20;
const getSpaceBetween = (isBlockDoubleWide?: boolean) => {
  if (isBlockDoubleWide) {
    return DOUBLE_BORDER_SPACE_BETWEEN_WIDE;
  }
  return DOUBLE_BORDER_SPACE_BETWEEN;
};

export const InnerDoubleBorder = styled.div`
  ${(props) => props.theme.MEDIA_QUERY.MOBILE} {
    padding-left: ${(props) => props.theme.SPACING.SM};
    padding-right: ${(props) => props.theme.SPACING.SM};
  }
`;

export const OuterDoubleBorder = styled.div<
  UniversalContainerProps & { isBlockDoubleWide?: boolean }
>`
  ${({ isSinglePage, isMobilePreview, isBlockDoubleWide }) =>
    getCommonContainerStyles(
      getSpaceBetween(isBlockDoubleWide),
      isSinglePage,
      undefined,
      isMobilePreview
    )}
  padding: ${({ isBlockDoubleWide }) => getSpaceBetween(isBlockDoubleWide)}px;
  ${({ isBlockDoubleWide, theme, evenSpacing, isDesktopPreview }) =>
    getMarginStyles({
      borderWidth: getSpaceBetween(isBlockDoubleWide),
      addY: true,
      mediaQuery: theme.MEDIA_QUERY,
      evenSpacing,
      isDesktopPreview,
    })}
  ${InnerDoubleBorder} {
    ${({ theme, evenSpacing }) => getSharedPadding(theme, evenSpacing)}
  }
`;
