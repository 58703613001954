import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { hexToRgb } from '@zola/zola-ui/src//util/color/hexToRgb';
/**
 *
 * @param c hex code string without '#'
 * @returns true if dark false if light color
 *
 * https://awik.io/determine-color-bright-dark-using-javascript/
 */
export const isDarkColor = (c: string): boolean => {
  // If hex --> Convert it to RGB: http://gist.github.com/983661
  const color = +`0x${c.toString().replace(c.length < 4 ? /./g : '', '$&$&')}`;
  // Variables for red, green, blue values
  // eslint-disable-next-line no-bitwise
  const r = color >> 16;
  // eslint-disable-next-line no-bitwise
  const g = (color >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = color & 255;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  // Using the HSP value, determine whether the color is light or dark
  return hsp < 127.5;
};

function luminance(r: number, g: number, b: number) {
  const a = [r, g, b].map((val) => {
    const v = val / 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
/**
 * https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
 * @param color1 hex color string prepended with '#'
 * @param color2 hex color string prepended with '#'
 * @returns true if color contrast passes AA-level large text WCAG standard
 */
export function getIsColorContrastSufficient(color1: string, color2: string): boolean {
  const color1rgb = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);
  if (!color1rgb || !color2rgb) return false;
  const color1luminance = luminance(color1rgb?.r, color1rgb?.g, color1rgb?.b);
  const color2luminance = luminance(color2rgb?.r, color2rgb?.g, color2rgb?.b);
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05);

  return ratio < 1 / 3;
}

export const getColorStyle = (color?: string | null): string =>
  color
    ? `
        color: #${color} !important;
      `
    : '';

export const getFontFamilyStyle = (fontFamily?: string | null): string =>
  fontFamily
    ? `
        font-family: ${fontFamily} !important;
      `
    : '';

export const getBackgroundColorStyle = (bgColor?: string | null): string =>
  bgColor
    ? `
    background: #${bgColor} !important;
  `
    : '';

export const getGenericOverride = (): SerializedStyles => {
  return css`
    #public-wrapper {
      .sticky-nav {
        #public-page-header h1 a {
          line-height: normal !important;
        }

        .pages-nav-container .list-inline > li a {
          line-height: normal !important;
        }
      }

      #page-hidden-banner {
        p {
          font-family: 'circular', serif !important;
          color: #ffffff !important;
          font-style: italic;
        }
      }

      #publish-website-banner {
        p,
        a {
          font-family: 'circular', serif !important;
          color: #ffffff !important;
          letter-spacing: normal;
        }
      }

      .hero-wrapper .home-page-hero .homepage-header-text h1 {
        line-height: normal !important;
        padding-top: ${SPACING.SM} !important;
        padding-bottom: ${SPACING.SM} !important;
      }

      .hero-wrapper .home-page-hero.has-image .homepage-header-text h1 {
        color: #ffffff !important;
      }

      .section-subtitle {
        font-weight: normal !important;
      }

      #registry-zola h6 {
        font-family: 'circular', serif !important;
      }

      .btn,
      .btn-primary {
        overflow-wrap: break-word !important;
        white-space: normal !important;
      }

      #rsvp-page-v2 {
        .form-group.attending {
          & > .row {
            display: flex;
            flex-flow: row;
            align-items: center;
          }
        }
      }
    }
  `;
};

export const getGlobalOverride = (backgroundColorOverride?: string): SerializedStyles | '' => {
  if (!backgroundColorOverride) return '';
  const bgStyle = getBackgroundColorStyle(backgroundColorOverride);
  return css`
    html {
      &#public-wedding-background {
        ${bgStyle}
      }

      &.public-wedding-page {
        ${bgStyle}

        body {
          ${bgStyle}
        }
      }
    }
  `;
};

export const getIconStyling = (
  backgroundColor?: string | null,
  headerFontColor?: string | null,
  bannerShown?: boolean
): SerializedStyles => {
  const isDarkBackground = backgroundColor ? isDarkColor(backgroundColor) : false;
  const conditionalBgColor = isDarkBackground ? 'FFFFFF' : '21201F';
  const color = headerFontColor || conditionalBgColor;
  const iconStyle = getColorStyle(color);
  return css`
    #public-wrapper {
      // Cart
      i {
        &.zolaicon-cart {
          ${backgroundColor && iconStyle}

          ${MEDIA_QUERY.DESKTOP} {
            // if banner, change on desktop
            ${bannerShown && getColorStyle('21201F')}
          }
        }
      }
      // Hamburger and 'x' mobile menu
      .sticky-nav .nav-control {
        ${backgroundColor && iconStyle}
      }
    }
  `;
};
