import React from 'react';
import { ServerResponse } from 'http';

import type { GetServerSideProps } from 'next';
import type { ParsedQuery } from 'query-string';
import type { WBabyPublicRegistryView, WPublicWeddingView } from '@zola/svc-web-api-ts-client';

import PublicV2, { PublicV2Props } from '~/components/publicWebsiteV2/PublicV2';
import BabyPageLayout from '~/components/baby/BabyPageLayout';
import type { LayoutProps } from '~/components/publicWebsiteV2/pageLayouts/types';
import {
  ssrGetBabyRegistryTheme,
  initializeZolaBabyRegistry,
  ssrGetPublicBabyRegistry,
} from '~/components/publicWebsiteV2/api/server/publicWebsiteSSRApiHelpers';

import { initializeStore } from '~/store/nextStore';
import { fetchUser } from '~/api/server/users';
import { receiveUser } from '~/actions/UserActions';
import { receiveWedding } from '~/actions/PublicWebsiteActions';
import { DeviceProvider } from '~/contexts/DeviceContext';

import Metadata from '~/components/baby/common/Metadata';
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';
import ErrorPage from '../404';

/**
 * Renders Wedding Website V2
 *
 */
const PublicWebsiteV2Container = ({
  wedding,
  babyRegistry,
  userAgent,
  pageDataContext,
  error,
}: PublicV2Props &
  LayoutProps & {
    userAgent?: string;
    query?: ParsedQuery;
    babyRegistry: WBabyPublicRegistryView;
    error?: boolean;
  }): JSX.Element => {
  if (error) {
    return <ErrorPage statusCode={404} />;
  }
  return (
    <DeviceProvider userAgent={userAgent}>
      <PublicV2 wedding={wedding} pageDataContext={pageDataContext} showMetadata={false}>
        <Metadata
          wedding={wedding as WPublicWeddingView}
          metaImageUrl={pageDataContext?.richPreviewImage}
        />
        <BabyPageLayout babyRegistry={babyRegistry} />
      </PublicV2>
    </DeviceProvider>
  );
};

const handlePageError = (res: ServerResponse) => {
  res.statusCode = 404;
  return {
    props: {
      error: true,
      faviconBrand: 'BABY',
    },
  };
};

export const getServerSideProps: GetServerSideProps = async ({ req, res, query }) => {
  try {
    const { slug, theme_id } = query;

    const userContext = (await fetchUser(req)) || {};

    const babyRegistry = await ssrGetPublicBabyRegistry(slug as string);

    const weddingThemeData = await ssrGetBabyRegistryTheme(
      Number(theme_id) || babyRegistry.theme_id || 800
    );
    const weddingData: WPublicWeddingView = {
      wedding_account_id: 0,
      wedding_account_uuid: '',
      slug: slug as string,
      owner_first_name: babyRegistry.owner_first_name,
      owner_last_name: babyRegistry.owner_last_name,
      partner_first_name: babyRegistry.partner_first_name,
      partner_last_name: babyRegistry.partner_last_name,
      enable_search_engine: babyRegistry.enable_search_engine,
      website_effects: [],
    };
    weddingData.public_theme_v2 = {
      ...weddingThemeData,
      key: 'test',
      sample_site_hero_image_url: '',
    };

    const reduxStore = initializeStore();
    const { dispatch, getState } = reduxStore;
    dispatch(receiveWedding(weddingData));
    if (userContext) {
      dispatch(receiveUser(userContext));
    }

    await initializeZolaBabyRegistry({ dispatch, slug: babyRegistry.slug, babyRegistry });

    const userAgent = req.headers['user-agent'];

    const initialReduxState = JSON.stringify(getState());

    return {
      props: JSON.parse(
        JSON.stringify({
          initialReduxState,
          wedding: weddingData,
          babyRegistry,
          userAgent,
          pageDataContext: {
            isHidden: false,
            richPreviewImage: babyRegistry.hero_image_uuid
              ? getZolaImageFromUuid(babyRegistry.hero_image_uuid)
              : null,
          },
          faviconBrand: 'BABY',
        })
      ),
    };
  } catch (e) {
    return handlePageError(res);
  }
};

export default PublicWebsiteV2Container;
