import React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import type { WBabyPublicRegistryView } from '@zola/svc-web-api-ts-client';

import InViewWrapper from '~/components/common/InViewWrapper/InViewWrapper';
import SiteHeader from '~/components/baby/common/Header';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import ContentContainerComponent from '~/components/publicWebsiteV2/common/ContentContainerComponent';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import BabyRegistryHeader from './BabyRegistryHeader';

const BabyRegistry = dynamic(() => import('./BabyRegistry'));
const Footer = dynamic(() => import('~/components/baby/common/Footer'), {
  ssr: false,
});

const BabyPageLayout = ({
  babyRegistry,
}: {
  babyRegistry: WBabyPublicRegistryView;
}): JSX.Element => {
  const {
    state: { inPreview },
  } = useWebsiteThemeContext();

  const ContentContainer = styled.div`
    min-height: 300px;
    ${MEDIA_QUERY.DESKTOP} {
      margin-top: -33px;
    }
  `;

  return (
    <>
      <SiteHeader />
      <ContentContainerComponent css={{ 'padding-top': 0, 'padding-bottom': 0 }}>
        <ContentContainer>
          <BabyRegistryHeader
            title={babyRegistry.title}
            arrivalDate={babyRegistry.arrival_date}
            greeting={babyRegistry.greeting}
            heroImageUuid={babyRegistry.hero_image_uuid}
          />
          <BabyRegistry babyRegistry={babyRegistry} />
        </ContentContainer>
        {inPreview ? (
          <Footer />
        ) : (
          <InViewWrapper>
            <Footer />
          </InViewWrapper>
        )}
      </ContentContainerComponent>
    </>
  );
};

export default BabyPageLayout;
