import styled from '@emotion/styled';
import ZolaBabyLogo from '@zola/zola-ui/src/components/ZolaBabyLogo';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import { mapFullFontViewToSerializedStyles } from '~/components/publicWebsiteV2/util/mappers';
import type { FullComponentFontView } from '~/components/publicWebsiteV2/util/mappers/types';

const NAV_ITEM_PADDING_BOTTOM = 8;

const getAnimatedLinkContainerStyles = () => {
  return `
    margin: 0 12px;
    padding-bottom: ${NAV_ITEM_PADDING_BOTTOM}px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      transform: scaleX(0);

      transition: transform 0.3s;
    }

    &:hover,
    &:focus {
      &:after {
          transform: scaleX(1);
      }
    }
  `;
};

export const AnimatedNavItem = styled.a<{ linkStyles: FullComponentFontView; active: boolean }>`
  ${getAnimatedLinkContainerStyles()}
  ${({ linkStyles, theme }) =>
    mapFullFontViewToSerializedStyles({ fontStyles: linkStyles, mediaQuery: theme.MEDIA_QUERY })}
  ${({ linkStyles, active }) => `
    &:after {
      background-color: #${linkStyles.color};
    }


    &:hover,
    &:focus {
      text-decoration: none;
      color: #${linkStyles.color};
    }

    ${
      active &&
      `
      text-decoration: none;
      color: #${linkStyles.color};

      &:after {
        transform: scaleX(1);
      }
    `
    }
  `}
`;

export const AnimatedNextLinkNavItem = styled.div<{
  linkStyles: FullComponentFontView;
  active: boolean;
}>`
  ${getAnimatedLinkContainerStyles()}
  a {
    ${({ linkStyles, theme }) =>
      mapFullFontViewToSerializedStyles({ fontStyles: linkStyles, mediaQuery: theme.MEDIA_QUERY })}
    &:hover, &:focus {
      text-decoration: none;
      color: ${({ linkStyles }) => ` #${linkStyles.color}`};
    }
  }
  ${({ linkStyles, active }) => `
    &:after {
      background-color: #${linkStyles.color};
    }

    &:hover,
    &:focus {
    }
    ${
      active &&
      `
      text-decoration: none;
      color: #${linkStyles.color};

      &:after {
        transform: scaleX(1);
      }
    `
    }
  `}
`;

export const NavLinkContainer = styled.ul`
  display: flex;
  justify-content: right;
  gap: 24px;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavContainer = styled.div<{
  inPreview?: boolean;
}>`
  position: ${({ inPreview }) => (inPreview ? 'relative' : 'sticky')};
  top: 0;
  ${MEDIA_QUERY.MOBILE} {
    padding: 10px 5%;
  }
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NavLogo = styled(ZolaBabyLogo)`
  ${MEDIA_QUERY.MOBILE} {
    width: 140px;
  }
  width: 200px;
}`;

export const NavLinkItem = styled.li`
  ${MEDIA_QUERY.MOBILE} {
    display: none;
  }
}`;

export const NavLinkItemCart = styled.li`
}`;
