import React from 'react';
import { getCart } from '~/actions/CartActions';
import { getCartSize as selectCartSize } from '~/selectors/cartSelectors';
import { useAppSelector } from '~/reducers/useAppSelector';
import { useAppDispatch } from '~/reducers/useAppDispatch';
import { CartIcon } from '@zola/zola-ui/src/components/SvgIcons/Cart';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { Container, IconContainer, CountBubble } from './HeaderCart.styles';

const HeaderCart = ({
  className,
  isDarkMode,
  addBoxShadow,
  cartIconUI,
}: {
  className?: string;
  isDarkMode: boolean;
  addBoxShadow?: boolean;
  cartIconUI?: JSX.Element;
}): JSX.Element | null => {
  const cartSize = useAppSelector(selectCartSize);
  const dispatch = useAppDispatch();

  const {
    state: { inPreview },
  } = useWebsiteThemeContext();

  useEffectOnce(() => {
    if (!inPreview) {
      // Fetch cart on mount
      dispatch(getCart()).catch(() => undefined);
    }

    const fetchCartWithDelay = () => {
      // Handle the delay from when web-nav updates and web-wedding calls for an update
      setTimeout(() => {
        dispatch(getCart()).catch(() => undefined);
      }, 300);
    };

    // Listen for web-nav cart events to update web-wedding
    window.addEventListener('CART_MODAL_REMOVE_ITEM', fetchCartWithDelay);

    return () => {
      window.removeEventListener('CART_MODAL_REMOVE_ITEM', fetchCartWithDelay);
    };
  });

  const getCountBubbleSize = () => {
    switch (cartSize.toString().length) {
      case 2:
        return 'MD';
      case 3:
        return 'LG';
      default:
        return 'SM';
    }
  };

  const showWebNavCart = () => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent('OPEN_MODAL_EVENT', { detail: 'CART' }));
    }
  };

  return (
    <Container className={className} onClick={showWebNavCart}>
      {cartIconUI || (
        <IconContainer isDarkMode={isDarkMode} addBoxShadow={addBoxShadow}>
          <CartIcon width={24} height={22} />
        </IconContainer>
      )}
      {cartSize > 0 && (
        <CountBubble isDarkMode={isDarkMode} size={getCountBubbleSize()}>
          {cartSize}
        </CountBubble>
      )}
    </Container>
  );
};

export default HeaderCart;
