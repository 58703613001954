import type { RootState } from '~/reducers/index';
import { createSelector } from 'reselect';

export const getCartState = (state: RootState) => state.cart;

export const getCartItems = createSelector(getCartState, (cart) => cart.items);

export const getCartIsReady = createSelector(
  getCartState,
  (cartState) => !!cartState && !cartState.busy
);

export const getCartSize = createSelector(getCartState, (cartState) => cartState?.size || 0);
